import './styles';
import React from 'react';
import { useState, useEffect } from 'react';

import withRouter from 'components/withRouter';
import Transition from 'components/Explorer/Transition';
import Query from 'components/Explorer/models/query';

import { mergeMentionCounts, mergeSentimentSummary } from './helpers';

import Fallback from './Fallback';
import HeadlineBar from './HeadlineBar';
import SummaryBarContent from './SummaryBarContent';

// Components

const SentimentSummary = ({ history }) => {
  const [mentionCounts, setMentionCounts] = useState({ withSentiment: 0 });
  const [sentimentSummary, setSentimentSummary] = useState({
    strongNegative: { scoreName: 'Strong negative', count: 0, percentage: 0 },
    negative: { scoreName: 'Negative', count: 0, percentage: 0 },
    neutralNegative: { scoreName: 'Neutral negative', count: 0, percentage: 0 },
    neutral: { scoreName: 'Neutral', count: 0, percentage: 0 },
    neutralPositive: { scoreName: 'Neutral positive', count: 0, percentage: 0 },
    positive: { scoreName: 'Positive', count: 0, percentage: 0 },
    strongPositive: { scoreName: 'Strong positive', count: 0, percentage: 0 }
  });
  const [reloadRequired, setReloadRequired] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [didFail, setFailed] = useState(false);

  useEffect(() => {
    const query = new Query(history);
    query.registerCallback(() => setReloadRequired(true), Query.EVENTS.didChangeFilters);

    if (!searchParamsPresent()) {
      setLoading(false);
      setReloadRequired(false);
      return () => query.cleanup();
    }

    setLoading(true);
    const inFlightRequest = query.highlightsSentiments();
    inFlightRequest.end((err, response) => {
      const loadingFailed = err || typeof response.body === 'undefined';
      if (!loadingFailed) {
        setMentionCounts(mergeMentionCounts(mentionCounts, response.body.mention_counts));
        setSentimentSummary(mergeSentimentSummary(sentimentSummary, response.body.summary));
      } else {
        setFailed(true);
      }

      setLoading(false);
      setReloadRequired(false);
    });

    return () => {
      if (isLoading) {
        inFlightRequest.abort();
      }
      query.cleanup();
    };
  }, [reloadRequired]);

  const trackInfoClicked = () => {
    Analytics.trackEvent('Sentiment Summary: Open-Popover-Button clicked', {});
  };

  const trackLinkClicked = () => {
    Analytics.trackEvent('Sentiment Summary: Learn-More-Link clicked', {});
  };

  const trackCloseClicked = () => {
    Analytics.trackEvent('Sentiment Summary: Close-Popover-Button clicked', {});
  };

  const searchParamsPresent = () => {
    const params = new URL(window.location).searchParams;
    params.delete('scope', 'all');

    return params.size > 0;
  };

  const ready = () => {
    const finishedLoading = !isLoading;
    const succeeded = !didFail;
    const hasMentionsWithSentiment = mentionCounts.withSentiment > 0;

    return finishedLoading && succeeded && hasMentionsWithSentiment && searchParamsPresent();
  };

  return (
    <div className="Highlights-Sentiment-Summary Highlights-block">
      <div className="Highlights-title">
        <HeadlineBar
          mentionCount={mentionCounts.withSentiment}
          onInfoClicked={trackInfoClicked}
          onLinkClicked={trackLinkClicked}
          onCloseClicked={trackCloseClicked}
          loading={isLoading}
        />
      </div>
      <div className="Highlights-content-wrapper">
        <Transition classNames="fade-in" timeout={250}>
          <div className="fade-in">
            {ready() ? (
              <SummaryBarContent sentimentSummary={sentimentSummary} />
            ) : (
              <Fallback
                isLoading={isLoading}
                mentionCounts={mentionCounts}
                didFail={didFail}
                searchParamsPresent={searchParamsPresent()}
              />
            )}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default withRouter(SentimentSummary);
