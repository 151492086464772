/* eslint-disable jsx-a11y/no-autofocus */

import React, { useState, useRef, useEffect } from 'react';
import './styles';

const FeedbackReasonModal = ({ onSubmit, onCancel }) => {
  const [feedbackReason, setFeedbackReason] = useState('');
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [visible, setVisible] = useState(true);

  const ref = useRef();

  useEffect(() => {
    if (visible) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [visible]);

  const handleChange = (evt) => {
    const value = evt.target.value;
    setFeedbackReason(value);
    setSubmitButtonEnabled(value.trim() === '' ? false : true);
  };

  const closeAndExecute = (fn) => {
    return (evt) => {
      evt.preventDefault();
      fn(feedbackReason);
      setVisible(false);
    };
  };

  return (
    <dialog className="sentiment-feedback-modal" ref={ref} onCancel={closeAndExecute(onCancel)}>
      <div className="top-bar">
        <h1 className="title">Share your feedback</h1>
      </div>
      <div className="body">
        <div className="sentiment-banner">
          <div className="ai-sparkles" alt="AI generated content icon"></div>
          <p>
            This feature uses AI to analyse Bluesky and X (formerly Twitter) posts. AI models can give incorrect
            answers.
            <br />
            <a className="learn-more-link" href="https://help.altmetric.com/support/solutions">
              Learn more about Sentiment Analysis
            </a>
          </p>
        </div>
        <form className="reason-form" id="reason-form" method="dialog" onSubmit={closeAndExecute(onSubmit)}>
          <label htmlFor="feedback-reason">Feedback Reason</label>
          <textarea
            name="feedback-reason"
            autoFocus={true}
            type="text"
            value={feedbackReason}
            onChange={handleChange}
            placeholder="Tell us why you chose this rating"
          ></textarea>
        </form>
      </div>

      <div className="bottom-bar">
        <button className="cancel" onClick={closeAndExecute(onCancel)}>
          Cancel
        </button>
        <button form="reason-form" className="submit" type="submit" disabled={!submitButtonEnabled}>
          Send Feedback
        </button>
      </div>
    </dialog>
  );
};

export default FeedbackReasonModal;
