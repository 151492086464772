import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Query from 'components/Explorer/models/query';
import ResearchOutput from 'components/Explorer/models/research_output';
import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';
import withFeatureFlags from 'components/Flipper/withFeatureFlags';
import Sentiment from './Sentiment';

class Output extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  query = null;

  static propTypes = {
    post: PropTypes.object.isRequired,
    output: PropTypes.instanceOf(ResearchOutput).isRequired,
    history: PropTypes.object.isRequired,
    featureFlags: PropTypes.array
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Mentions-Output">
        <Link to={this.query.withShowDetails(this.props.output.id).location}>
          <div className="badge">
            <img
              src={this.props.output.badgeUrl}
              loading="lazy"
              width="50"
              height="50"
              alt={I18n.t('Explorer.Mentions.badge.alt', {
                score: this.props.output.score
              })}
            />
            {this.props.output.verified && (
              <div className="verified-badge" data-testid="verified-badge">
                <div
                  className="icon"
                  title={I18n.t('Explorer.Outputs.ResearchOutputItem.verified', {
                    name: window.current_user.institution_name
                  })}
                />
              </div>
            )}
          </div>
        </Link>

        <div>
          <div className="title">
            <span className="main">{this.props.output.title}</span>
            <span className="source">{this.props.output.meta}</span>
          </div>

          {this.props.featureFlags.includes('sentiment') && this.props.output.sentimentValue && (
            <Sentiment output={this.props.output} post={this.props.post} />
          )}
        </div>
      </div>
    );
  }
}

export default withFeatureFlags(withRouter(Output));
