// Utility functions
const getCsrfToken = () => {
  const meta = document.querySelector('meta[name="csrf-token"]');
  return meta && meta.getAttribute('content');
};

exports.postFeedback = (payload) => {
  return new Promise((resolve, reject) => {
    fetch('/explorer/sentiment_feedbacks', {
      method: 'post',
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.body);
        } else {
          reject(response.body || response.statusText);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
