const sentimentNameToScore = new Map([
  ['Strong negative', '-3'],
  ['Negative', '-2'],
  ['Neutral negative', '-1'],
  ['Neutral', '0'],
  ['Neutral positive', '1'],
  ['Positive', '2'],
  ['Strong positive', '3']
]);

const namesWithScores = Array.from(sentimentNameToScore.entries());

function getScoreForName(scoreName, { asInteger = false } = {}) {
  const lowerName = scoreName.toLowerCase();

  for (const [sentimentName, sentimentScore] of namesWithScores) {
    if (sentimentName.toLowerCase() === lowerName) {
      return asInteger ? parseInt(sentimentScore) : sentimentScore;
    }
  }

  throw new Error(`Unexpected sentiment: "${scoreName}"`);
}

export default { namesWithScores, getScoreForName };
