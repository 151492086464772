import React from 'react';

import AiSparklesIcon from 'icons/ai-sparkles.svg';

const InfoPopover = ({ isOpen, onLinkClicked, onCloseClicked }) => {
  return (
    <div id="infoPopover" popover="auto" className={isOpen ? 'is-open' : undefined} data-testid={'popover'}>
      <div className="infoPopover-title">What is Sentiment Analysis?</div>
      <div className="infoPopover-inner">
        <div className="infoPopover-note">
          <div>
            <AiSparklesIcon />
          </div>
          <div>
            <p>
              This feature uses AI to analyse Bluesky and X (formerly Twitter) posts. AI models can give incorrect
              answers.
            </p>
            <a onClick={onLinkClicked} href="https://example.com" target="_blank" rel="noreferrer">
              Learn more about Sentiment Analysis
            </a>
          </div>
        </div>
        <div className="infoPopover-body">
          <p>
            We use Sentiment Analysis to provide insight into the kind of attention your research has accrued. Using an
            AI model we can apply a sentiment rating to each mention on a scale from strong positive to strong negative
            depending on the content and context of the post. This gives you a better understanding of what people are
            saying about your research.
          </p>
        </div>
        <div className="infoPopover-footer">
          <div className="infoPopover-buttons">
            <button
              type="button"
              className="close"
              onClick={onCloseClicked}
              popovertarget="infoPopover"
              popovertargetaction="close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPopover;
