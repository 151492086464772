import React from 'react';

import ThumbsUp from 'components/SvgIcons/ThumbsUp';
import ThumbsDown from 'components/SvgIcons/ThumbsDown';

const InitialState = ({ submitFeedback }) => {
  return (
    <>
      <p className="question">Is this analysis correct?</p>
      <button
        className="feedback-button thumbs-up"
        onClick={() => submitFeedback({ feedbackRating: 'correct' })}
        data-testid="sentiment-feedback-thumbs-up"
      >
        <ThumbsUp className="feedback-icon" />
      </button>
      <button
        className="feedback-button thumbs-down"
        onClick={() => submitFeedback({ feedbackRating: 'incorrect' })}
        data-testid="sentiment-feedback-thumbs-down"
      >
        <ThumbsDown className="feedback-icon" />
      </button>
    </>
  );
};

const ThankYou = () => <p className="message">Thank you for your feedback.</p>;

const AskWhy = ({ onClick }) => {
  return (
    <button className="question clickable" onClick={onClick}>
      Tell us why you chose this rating
    </button>
  );
};

export default { InitialState, AskWhy, ThankYou };
