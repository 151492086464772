import React, { useState } from 'react';

import NumberHelper from '../../../util/number_helper';

import AiSparklesIcon from 'icons/ai-sparkles.svg';

import InfoPopover from './InfoPopover';

const HeadlineBar = ({ mentionCount, onInfoClicked, onLinkClicked, onCloseClicked, loading }) => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  let displayedCount = '';

  if (!loading && mentionCount > 0) {
    displayedCount = (
      <span className="mention-count" data-testid="mention-count">
        {NumberHelper.formatStatistic(mentionCount)}
      </span>
    );
  }

  const openClicked = () => {
    onInfoClicked();
    setPopoverIsOpen(true);
  };

  const closeClicked = () => {
    onCloseClicked();
    setPopoverIsOpen(false);
  };

  return (
    <>
      <div className="ai-sparkles">
        <AiSparklesIcon />
      </div>
      <h2>
        Sentiment Analysis for {displayedCount} Bluesky and X {mentionCount === 1 ? 'mention' : 'mentions'}
      </h2>
      <button className="help" onClick={openClicked} popovertarget="infoPopover" popovertargetaction="show">
        <span aria-hidden>&#xe913;</span>
        <span className="visually-hidden">Learn more about Sentiment Analysis</span>
      </button>
      <InfoPopover isOpen={popoverIsOpen} onLinkClicked={onLinkClicked} onCloseClicked={closeClicked} />
    </>
  );
};

export default HeadlineBar;
