import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Category from './category';
import NumberHelper from 'util/number_helper';

const LabelCategory = forwardRef(({ category, onClick }, ref) => {
  const style = {
    backgroundColor: category.color
  };

  return (
    <button className="label" onClick={onClick} ref={ref}>
      <div className="swatch" style={style} />
      <div>
        <strong>
          {I18n.t(category.name, {
            scope: 'Explorer.Highlights.Breakdown.categories'
          })}
        </strong>

        <p className="count">
          {I18n.t('Explorer.Highlights.Breakdown.mentions', {
            count: category.mentionCount,
            formattedCount: NumberHelper.formatNumberWithDelimiter(category.mentionCount)
          })}
        </p>
      </div>
    </button>
  );
});

LabelCategory.displayName = 'LabelCategory';

LabelCategory.propTypes = {
  category: PropTypes.instanceOf(Category).isRequired,
  onClick: PropTypes.func.isRequired
};

export default LabelCategory;
