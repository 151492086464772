import * as React from 'react';
const ThumbsDown = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <mask
      id="a"
      width={16}
      height={16}
      x={2}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha'
      }}
    >
      <path fill="#D9D9D9" d="M2 2h16v16H2z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#383844"
        d="M6 4h7.333v8.667l-4.666 4.666-.834-.833a.875.875 0 0 1-.191-.317 1.09 1.09 0 0 1-.075-.383v-.233l.733-2.9H4a1.28 1.28 0 0 1-.933-.4 1.28 1.28 0 0 1-.4-.934V10c0-.078.01-.161.033-.25.022-.089.045-.172.067-.25l2-4.7c.1-.222.266-.411.5-.567C5.5 4.078 5.745 4 6 4Zm6 1.333H6L4 10v1.333h6L9.1 15l2.9-2.9V5.333Zm1.333 7.334v-1.334h2v-6h-2V4h3.334v8.667h-3.334Z"
      />
    </g>
  </svg>
);
export default ThumbsDown;
