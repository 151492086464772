import React from 'react';
import { Sidebar } from '@digital-science/sidebar';
import { SidebarItem } from '@digital-science/sidebar-item';
import { Icon } from '@digital-science/icon';
import ExplorerLogo from './Sidebar/ExplorerLogo';
import ExplorerSidebarItem from './Sidebar/ExplorerSidebarItem';
import SidebarFooterIcon from './Sidebar/SidebarFooterIcon';
import '@digital-science/ui-styles';
import './Sidebar.sass';

function ExplorerSidebar({ items, currentSelectedId }) {
  const {
    explore,
    savedSearches,
    myInstitution,
    appsAndModules,
    appsAndModulesLearnMore,
    account,
    accountSettings,
    adminPanel,
    signOut,
    signIn,
    signUp,
    whatsNew,
    support
  } = items;

  return (
    <Sidebar className="ExplorerSidebar" ariaLabel="main">
      <Sidebar.Context.Consumer>
        {({ expanded }) => (
          <>
            <a href={explore.href}>
              <ExplorerLogo />
              <span className="visually-hidden">Altmetric Explorer</span>
            </a>
            <Sidebar.Separator />
            <ExplorerSidebarItem
              id={explore.id}
              href={explore.href}
              icon="MagnifyingGlass"
              text="Search & Explore"
              selectedId={currentSelectedId}
              expanded={expanded}
            />
            {savedSearches && (
              <ExplorerSidebarItem
                id={savedSearches.id}
                href={savedSearches.href}
                icon="Star"
                text="Saved Searches"
                selectedId={currentSelectedId}
                expanded={expanded}
              />
            )}
            {myInstitution && (
              <ExplorerSidebarItem
                id={myInstitution.id}
                href={myInstitution.href}
                icon="BuildingOffice"
                text="My Institution"
                selectedId={currentSelectedId}
                expanded={expanded}
              />
            )}
            {appsAndModulesLearnMore && (
              <>
                <Sidebar.Separator />
                <SidebarItem expanded={expanded}>
                  <SidebarItem.Icon>
                    <Icon name="CirclesThreePlus" size="lg" />
                  </SidebarItem.Icon>
                  <SidebarItem.Text>Apps & Modules</SidebarItem.Text>
                  <Sidebar.Block>
                    {appsAndModules?.map((link) => (
                      <ExplorerSidebarItem
                        key={link.id}
                        id={link.id}
                        href={link.url}
                        text={link.name}
                        summary={link.summary}
                        icon="BookOpen"
                        expanded={true}
                        secondLevel={true}
                        external
                      />
                    ))}
                    <Sidebar.Separator />
                    <ExplorerSidebarItem
                      id="learn-more"
                      href={appsAndModulesLearnMore.href}
                      text="Learn about our apps & modules"
                      expanded
                      external
                      secondLevel
                    />
                  </Sidebar.Block>
                  <SidebarItem.Indicator>
                    <SidebarItem.Icon disableUnderline>
                      <Icon name="DotsThreeVertical" size="md" />
                    </SidebarItem.Icon>
                  </SidebarItem.Indicator>
                </SidebarItem>
              </>
            )}
            <div className="UpperFooter">
              <Sidebar.Block>
                <SidebarItem expanded={expanded} id={account.id} selectedId={currentSelectedId}>
                  <SidebarItem.Icon>
                    <Icon name="User" size="lg" />
                  </SidebarItem.Icon>
                  <SidebarItem.Text>Account</SidebarItem.Text>
                  <Sidebar.Block>
                    <SidebarItem as="div" secondLevel>
                      <SidebarItem.Text>
                        <div className="ExplorerSidebar-userinfo">
                          <p className="ExplorerSidebar-user-identifier">{account.email || 'Anonymous user'}</p>
                          {account.institution && (
                            <p className="ExplorerSidebar-user-institution">{account.institution}</p>
                          )}
                          <p>
                            {account.roles?.map((role) => (
                              <span className="ExplorerSidebar-user-roles" key={role}>
                                {role}
                              </span>
                            ))}
                          </p>
                        </div>
                      </SidebarItem.Text>
                    </SidebarItem>
                    {accountSettings && (
                      <ExplorerSidebarItem
                        id="account-settings"
                        href={accountSettings.href}
                        icon="User"
                        text="My Account"
                        expanded
                        secondLevel
                      />
                    )}
                    {adminPanel && (
                      <ExplorerSidebarItem
                        id="admin-panel"
                        href={adminPanel.href}
                        icon="Wrench"
                        text="Admin Panel"
                        expanded
                        secondLevel
                      />
                    )}
                    {signUp && (
                      <ExplorerSidebarItem
                        id="sign-up"
                        href={signUp.href}
                        icon="User"
                        text="Create an Account"
                        expanded
                        secondLevel
                      />
                    )}
                    {signIn && (
                      <ExplorerSidebarItem
                        id="sign-in"
                        href={signIn.href}
                        icon="SignIn"
                        text="Sign In"
                        expanded
                        secondLevel
                      />
                    )}
                    {signOut && (
                      <>
                        <Sidebar.Separator />
                        <ExplorerSidebarItem
                          id="sign-out"
                          href={signOut.href}
                          icon="SignOut"
                          text="Sign Out"
                          expanded
                          secondLevel
                        />
                      </>
                    )}
                  </Sidebar.Block>
                  <SidebarItem.Indicator>
                    <SidebarItem.Icon disableUnderline>
                      <Icon name="DotsThreeVertical" size="md" />
                    </SidebarItem.Icon>
                  </SidebarItem.Indicator>
                </SidebarItem>
              </Sidebar.Block>
              <Sidebar.Block>
                <SidebarItem expanded={expanded}>
                  <SidebarItem.Icon>
                    <Icon name="Question" size="lg" />
                  </SidebarItem.Icon>
                  <SidebarItem.Text>Help</SidebarItem.Text>
                  <Sidebar.Block>
                    <ExplorerSidebarItem
                      id="what-is-new"
                      href={whatsNew.href}
                      text="What's New"
                      summary="The latest updates to Altmetric products"
                      icon="RocketLaunch"
                      expanded
                      external
                      secondLevel
                    />
                    <Sidebar.Separator />
                    <ExplorerSidebarItem
                      id="support-center"
                      href={support.href}
                      text="Support Center"
                      expanded
                      external
                      secondLevel
                    />
                  </Sidebar.Block>
                  <SidebarItem.Indicator>
                    <SidebarItem.Icon disableUnderline>
                      <Icon name="DotsThreeVertical" size="md" />
                    </SidebarItem.Icon>
                  </SidebarItem.Indicator>
                </SidebarItem>
              </Sidebar.Block>
              <Sidebar.Separator />
            </div>
            <Sidebar.Footer expanded={expanded}>
              <Sidebar.Footer.Expanded>
                <SidebarFooterIcon />
              </Sidebar.Footer.Expanded>
              <Sidebar.Footer.Collapsed>
                <div className="ExplorerSidebar-footer-collapsed">
                  DIGITAL
                  <br />
                  SCIENCE
                </div>
              </Sidebar.Footer.Collapsed>
            </Sidebar.Footer>
          </>
        )}
      </Sidebar.Context.Consumer>
    </Sidebar>
  );
}

export default ExplorerSidebar;
