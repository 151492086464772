import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { createBrowserHistory as createHistory } from 'history';
import FeatureFlagProvider from 'components/Flipper/FeatureFlagProvider';

import Query from 'components/Explorer/models/query';
import ResearchOutput from 'components/Explorer/models/research_output';

import Header from './Header';
import TabBar from './TabBar';
import ContentPanel from './ContentPanel';
import DetailPage from './DetailPage';
import AdvancedSearch from './AdvancedSearch';
import SearchTerm from 'models/SearchTerm';

class Explorer extends React.Component {
  history = null;
  historyUnlisten = null;
  query = null;

  static propTypes = {
    instanceLogo: PropTypes.string.isRequired,
    instanceName: PropTypes.string.isRequired
  };

  state = {
    showDetailPageForOutput: null,
    advancedSearchVisible: false,
    initialSearchTerm: SearchTerm.empty()
  };

  constructor(props) {
    super(props);

    this.history = createHistory({ basename: '/explorer' });
    this.query = new Query(this.history);
  }

  componentDidMount() {
    this.query.registerCallback(this.updateDetailPageState, Query.EVENTS.didChangeShowDetails);

    this.updateDetailPageState();
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  render() {
    return (
      <Router history={this.history}>
        <FeatureFlagProvider>
          <div className="Explorer">
            <div
              className="Explorer-content-wrapper"
              {...{ inert: this.state.showDetailPageForOutput !== null ? '' : undefined }}
            >
              <Header
                logo={this.props.instanceLogo}
                instanceName={this.props.instanceName}
                onShowAdvancedSearch={this.showAdvancedSearch}
                onSelectShowMore={this.selectShowMore}
              />
              <div className="Explorer-content-panel-wrapper">
                {window.current_user.librarian ? null : <TabBar />}
                <ContentPanel onRouteChangeUpdate={window.Analytics.pageChange} />
              </div>
            </div>
            {this.state.showDetailPageForOutput && <DetailPage output={this.state.showDetailPageForOutput} />}
            <div className="Explorer-advanced-search-wrapper">
              {this.state.advancedSearchVisible && (
                <AdvancedSearch
                  key="advancedSearch"
                  onClose={this.closeAdvancedSearch}
                  initialSearchTerm={this.state.initialSearchTerm}
                />
              )}
            </div>
          </div>
        </FeatureFlagProvider>
      </Router>
    );
  }

  updateDetailPageState = () => {
    const newOutput = this.query.showDetails ? new ResearchOutput({ id: this.query.showDetails }) : null;

    this.setState({ showDetailPageForOutput: newOutput });
  };

  showAdvancedSearch = () => {
    this.setState({
      advancedSearchVisible: true,
      initialSearchTerm: SearchTerm.empty()
    });
  };

  closeAdvancedSearch = () => {
    this.setState({
      advancedSearchVisible: false,
      initialSearchTerm: SearchTerm.empty()
    });
  };

  selectShowMore = (searchTerm) => {
    this.setState({
      advancedSearchVisible: true,
      initialSearchTerm: searchTerm
    });
  };
}

export default hot(module)(Explorer);
