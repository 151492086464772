import React from 'react';

const SidebarFooterIcon = ({ height = '20', width = '73', color = 'currentColor' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 73 20" fill="none">
      <g clipPath="url(#clip0_1951_468)">
        <path
          d="M20.1563 10.9419H16.3898V0.202393H20.0945C23.8307 0.202393 25.7752 2.36629 25.7752 5.55698C25.7752 8.74767 23.7834 10.9431 20.1563 10.9431V10.9419ZM20.0157 2.27173H19.0507V8.87132H20.0157C21.9153 8.87132 23.0973 7.87484 23.0973 5.57153C23.0973 3.26822 21.8365 2.27173 20.0157 2.27173Z"
          fill={color}
        />
        <path d="M27.4275 10.9419V0.202393H30.0896V10.9431H27.4275V10.9419Z" fill={color} />
        <path
          d="M37.0165 11.1444C33.6076 11.1444 31.725 8.9029 31.725 5.58734C31.725 2.27179 33.764 0 37.0784 0C38.4021 0 39.5065 0.311553 40.3624 0.794036L40.0048 3.1131C39.195 2.5991 38.2458 2.19421 37.1402 2.19421C35.2878 2.19421 34.3859 3.56407 34.3859 5.5728C34.3859 7.58153 35.3194 9.012 37.1717 9.012C37.7633 9.012 38.1839 8.88714 38.6191 8.67014V6.75597H36.751L36.4395 4.77876H40.9382V10.0085C39.8169 10.7395 38.4943 11.1444 37.0153 11.1444H37.0165Z"
          fill={color}
        />
        <path d="M42.793 10.9419V0.202393H45.4551V10.9431H42.793V10.9419Z" fill={color} />
        <path
          d="M52.6001 2.45964V10.9431H49.9235V2.45964H46.8879V0.202393H55.3871L55.7605 2.45964H52.6014H52.6001Z"
          fill={color}
        />
        <path
          d="M58.6409 0.155151H61.5673L65.3035 10.6146L62.6268 11.0498L61.8328 8.71498H58.0966L57.3813 10.9407H54.7822L58.6409 0.155151ZM58.7342 6.62988H61.1624L59.9635 2.89367L58.7342 6.62988Z"
          fill={color}
        />
        <path d="M66.4879 10.9419V0.202393H69.1488V8.63857H72.4486L72.8075 10.9419H66.4879Z" fill={color} />
        <path
          d="M18.6106 19.9709C17.7111 19.9709 16.8249 19.6993 16.2236 19.3708L16.3521 18.7138C16.9813 19.0714 17.7947 19.3708 18.6797 19.3708C19.681 19.3708 20.3381 18.9271 20.3381 18.1428C20.3381 17.4421 19.9235 17.0857 18.4809 16.6844C17.0662 16.2989 16.4237 15.8128 16.4237 14.7848C16.4237 13.7568 17.3098 13.0137 18.6385 13.0137C19.3961 13.0137 20.0677 13.1991 20.6096 13.4707L20.4957 14.1132C19.881 13.7992 19.2531 13.6283 18.6094 13.6283C17.5523 13.6283 17.0807 14.129 17.0807 14.7424C17.0807 15.3558 17.4808 15.6867 18.6809 16.014C20.2108 16.4286 20.9963 16.9572 20.9963 18.0858C20.9963 19.3005 20.0108 19.9721 18.6106 19.9721V19.9709Z"
          fill={color}
        />
        <path
          d="M25.2986 20C23.656 20 22.3419 18.7138 22.3419 16.5002C22.3419 14.2866 23.6706 13.0004 25.3423 13.0004C26.1848 13.0004 26.9279 13.2998 27.5147 13.7435L27.385 14.4296C26.7134 13.8574 26.0854 13.5871 25.3144 13.5871C23.8851 13.5871 23.0426 14.7727 23.0426 16.5014C23.0426 18.2301 23.8573 19.4157 25.3714 19.4157C26.0999 19.4157 26.7728 19.1587 27.4722 18.5586L27.5583 19.1866C26.9449 19.7018 26.2018 20.0012 25.3011 20.0012L25.2986 20Z"
          fill={color}
        />
        <path d="M29.1901 19.857V13.1422H29.8483V19.857H29.1901Z" fill={color} />
        <path
          d="M34.5822 20C32.8111 20 31.6243 18.6859 31.6243 16.5002C31.6243 14.3145 32.8535 13.0004 34.4828 13.0004C36.1969 13.0004 37.054 14.4999 37.054 16.3571V16.5002H32.3116C32.3116 18.2143 33.1117 19.4145 34.6113 19.4145C35.4259 19.4145 36.283 18.9865 36.9401 18.3865L37.0395 19.0156C36.4249 19.5721 35.5399 20.0012 34.5822 20.0012V20ZM32.3395 15.9425H36.3545C36.3121 14.6284 35.6538 13.5568 34.4828 13.5568C33.2111 13.5568 32.4813 14.5424 32.3395 15.9425Z"
          fill={color}
        />
        <path
          d="M43.3324 19.857V15.1849C43.3324 14.1702 42.8463 13.6138 41.8886 13.6138C41.1455 13.6138 40.3745 14.0429 39.5307 14.8285V19.857H38.8591V13.1422H39.3453L39.5162 14.1569C40.3308 13.4428 41.0739 13.0004 41.9601 13.0004C43.3469 13.0004 44.0028 13.7859 44.0028 15.1291V19.8582H43.3324V19.857Z"
          fill={color}
        />
        <path
          d="M48.6822 20C47.0383 20 45.7242 18.7138 45.7242 16.5002C45.7242 14.2866 47.0529 13.0004 48.7246 13.0004C49.5671 13.0004 50.3103 13.2998 50.8958 13.7435L50.7673 14.4296C50.0969 13.8574 49.4665 13.5871 48.6967 13.5871C47.2687 13.5871 46.4249 14.7727 46.4249 16.5014C46.4249 18.2301 47.2396 19.4157 48.7525 19.4157C49.4811 19.4157 50.1539 19.1587 50.8533 18.5586L50.9394 19.1866C50.3248 19.7018 49.5817 20.0012 48.6822 20.0012V20Z"
          fill={color}
        />
        <path
          d="M55.0527 20C53.2815 20 52.0947 18.6859 52.0947 16.5002C52.0947 14.3145 53.324 13.0004 54.952 13.0004C56.6674 13.0004 57.5233 14.4999 57.5233 16.3571V16.5002H52.7809C52.7809 18.2143 53.5798 19.4145 55.0805 19.4145C55.894 19.4145 56.7523 18.9865 57.4093 18.3865L57.5087 19.0156C56.8941 19.5721 56.0091 20.0012 55.0514 20.0012L55.0527 20ZM52.81 15.9425H56.825C56.7814 14.6284 56.1243 13.5568 54.952 13.5568C53.6804 13.5568 52.953 14.5424 52.8088 15.9425H52.81Z"
          fill={color}
        />
        <path d="M14.5083 2.96033H12.0414V5.42729H14.5083V2.96033Z" fill={color} />
        <path d="M8.98516 0.202393H6.51941V2.66936H8.98516V0.202393Z" fill={color} />
        <path d="M11.7443 0.202393H9.27734V2.66815H11.7443V0.202393Z" fill={color} />
        <path d="M14.5083 0.201294H12.0414V2.66826H14.5083V0.201294Z" fill={color} />
        <path d="M8.98516 2.96033H6.51941V5.42729H8.98516V2.96033Z" fill={color} />
        <path d="M11.7443 2.96033H9.27734V5.42729H11.7443V2.96033Z" fill={color} />
        <path d="M11.7443 5.71826H9.27734V8.18402H11.7443V5.71826Z" fill={color} />
        <path d="M14.5083 5.71826H12.0414V8.18523H14.5083V5.71826Z" fill={color} />
        <path d="M11.7443 8.4762H9.27734V10.9432H11.7443V8.4762Z" fill={color} />
        <path d="M14.5083 8.4762H12.0414V10.9432H14.5083V8.4762Z" fill={color} />
        <path d="M6.03339 4.36414H4.25256V6.14617H6.03339V4.36414Z" fill={color} />
        <path d="M4.26105 5.24915H2.48022V7.02997H4.26105V5.24915Z" fill={color} />
        <path d="M3.74335 8.26404H1.96252V10.0449H3.74335V8.26404Z" fill={color} />
        <path d="M5.61872 9.58667H3.83911V11.3675H5.61872V9.58667Z" fill={color} />
        <path d="M6.75226 7.65308H4.97144V9.4339H6.75226V7.65308Z" fill={color} />
        <path d="M9.70164 7.17053H7.48804V9.38292H9.70164V7.17053Z" fill={color} />
        <path d="M6.22122 13.381H4.20764V15.3933H6.22122V13.381Z" fill={color} />
        <path d="M4.96293 14.8939H3.98584V15.871H4.96293V14.8939Z" fill={color} />
        <path d="M13.2318 13.6865H12.1722V14.746H13.2318V13.6865Z" fill={color} />
        <path d="M10.2035 11.3929H8.42151V13.175H10.2035V11.3929Z" fill={color} />
        <path d="M5.93268 1.09705H4.15186V2.87787H5.93268V1.09705Z" fill={color} />
        <path d="M10.2036 13.2452H9.09558V14.3545H10.2036V13.2452Z" fill={color} />
        <path d="M8.29668 13.3932H6.95227V14.7376H8.29668V13.3932Z" fill={color} />
        <path d="M13.1154 16.2311H11.7722V17.5743H13.1154V16.2311Z" fill={color} />
        <path d="M7.80694 17.778H6.70984V18.8751H7.80694V17.778Z" fill={color} />
        <path d="M1.43763 7.98523H0.192627V9.22902H1.43763V7.98523Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_1951_468">
          <rect width="72.6149" height="20" fill="white" transform="translate(0.192627)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SidebarFooterIcon;
