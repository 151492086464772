import React from 'react';
import { SidebarItem } from '@digital-science/sidebar-item';
import { Icon } from '@digital-science/icon';

const ExplorerSidebarItem = ({ id, href, icon, text, summary, expanded, external, secondLevel, selectedId }) => {
  const target = external ? { target: '_blank' } : {};

  return (
    <SidebarItem
      as="a"
      href={href}
      id={id}
      selectedId={selectedId}
      expanded={expanded}
      secondLevel={secondLevel}
      {...target}
    >
      {icon && (
        <SidebarItem.Icon disableUnderline={secondLevel}>
          <Icon name={icon} size="lg" />
        </SidebarItem.Icon>
      )}
      <SidebarItem.Text>
        {text}
        <>{summary && <SidebarItem.TextSecondary>{summary}</SidebarItem.TextSecondary>}</>
      </SidebarItem.Text>
      {external && (
        <SidebarItem.Indicator>
          <SidebarItem.Icon disableUnderline>
            <Icon name="ArrowSquareOut" size="md" />
          </SidebarItem.Icon>
        </SidebarItem.Indicator>
      )}
    </SidebarItem>
  );
};

export default ExplorerSidebarItem;
