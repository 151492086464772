import React, { useState } from 'react';

import sentimentHelpers from 'components/Explorer/util/sentiment_helpers';

import api from './api';
import WorkflowSteps from './WorkflowSteps';
import FeedbackReasonModal from './FeedbackReasonModal';
import './styles';

// Component
const FeedbackWorkflow = ({ postType, researchOutputId, postId, postUrl, sentimentValue }) => {
  // Response helper
  const submitFeedback = ({ feedbackRating = '', feedbackReason = '' }) => {
    if (feedbackReason !== '') {
      Analytics.trackEvent(`Sentiment Feedback: Feedback-Reason submitted`, {});
    } else if (feedbackRating !== '') {
      Analytics.trackEvent('Sentiment Feedback:  Feedback-Rating submitted', { feedbackRating });
    }

    const intValue = sentimentHelpers.getScoreForName(sentimentValue, { asInteger: true });
    const body = {
      sentiment_feedback: {
        source: 'explorer',
        post_type: postType,
        research_output_id: researchOutputId,
        post_id: postId,
        post_url: postUrl,
        sentiment_value: intValue,
        feedback_rating: feedbackRating,
        feedback_reason: feedbackReason
      }
    };
    api.postFeedback(body).then(stepForwards).catch(setError);
  };

  // Workflow definition
  const workflow_steps = ['initial', 'promptForReason', 'showReasonForm', 'feedbackSubmitted'];
  const [currentStep, setStep] = useState(0);
  const [error, setError] = useState(null);

  const stepForwards = () => setStep(currentStep + 1);
  const stepBackwards = () => setStep(currentStep - 1);

  const Body = () => {
    switch (workflow_steps[currentStep]) {
      case 'initial':
        return <WorkflowSteps.InitialState submitFeedback={submitFeedback} />;
      case 'promptForReason':
        return (
          <>
            <WorkflowSteps.ThankYou />
            <WorkflowSteps.AskWhy onClick={stepForwards} />
          </>
        );
      case 'showReasonForm':
        return (
          <FeedbackReasonModal
            onSubmit={(feedbackReason) => submitFeedback({ feedbackReason })}
            onCancel={stepBackwards}
          />
        );
      case 'feedbackSubmitted':
        return <WorkflowSteps.ThankYou />;
      default:
        throw new Error(`Unexpected workflow step: ${currentStep} (resolved to ${workflow_steps[currentStep]})`);
    }
  };

  return (
    <div className="Mentions-Output-Sentiment-FeedbackForm">
      <div className="sentiment-feedback-workflow-container">
        {error ? <p>Error sending feedback, please try again later.</p> : <Body />}
      </div>
    </div>
  );
};

export default FeedbackWorkflow;
