import { kebabCase } from './helpers';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import React from 'react';
import { Link } from 'react-router-dom';
import sentimentHelpers from 'components/Explorer/util/sentiment_helpers';
import Query from 'components/Explorer/models/query';
import withRouter from 'components/withRouter';

const SummaryBarContent = ({ sentimentSummary, history }) => {
  return (
    <>
      <SummaryBarChart summary={sentimentSummary} />
      <SummaryLabels summary={sentimentSummary} history={history} />
    </>
  );
};

const SummaryBarChart = ({
  summary: { strongNegative, negative, neutralNegative, neutral, neutralPositive, positive, strongPositive }
}) => {
  return (
    <div className="Sentiment-Summary-chart" aria-hidden>
      <SummaryBar value={strongNegative} />
      <SummaryBar value={negative} />
      <SummaryBar value={neutralNegative} />
      <SummaryBar value={neutral} />
      <SummaryBar value={neutralPositive} />
      <SummaryBar value={positive} />
      <SummaryBar value={strongPositive} />
    </div>
  );
};

const SummaryBar = ({ value: { scoreName, percentage } }) => {
  if (percentage === 0) {
    return <></>;
  }
  // Inline styles allow CSS to do the layout maths
  const dataScoreName = kebabCase(scoreName);
  const percentageLabel = Math.ceil(percentage || 0);
  return (
    <div
      className={`bar ${dataScoreName}`}
      style={{ width: `${percentage}%` }}
      data-tooltip-id={`${dataScoreName}-bar`}
      data-tooltip-content={`${scoreName} (${percentageLabel}% of mentions)`}
      data-testid={`sentiment-bar:${dataScoreName}:${percentage}%`}
    >
      <ReactTooltip id={`${dataScoreName}-bar`} effect="solid" />
    </div>
  );
};

const SummaryLabels = ({
  summary: { strongNegative, negative, neutralNegative, neutral, neutralPositive, positive, strongPositive },
  history
}) => {
  const query = new Query(history);

  return (
    <ul className="Sentiment-Summary-labels">
      <li>
        <SummaryLabel value={strongNegative} query={query} />
      </li>
      <li>
        <SummaryLabel value={negative} query={query} />
      </li>
      <li>
        <SummaryLabel value={neutralNegative} query={query} />
      </li>
      <li>
        <SummaryLabel value={neutral} query={query} />
      </li>
      <li>
        <SummaryLabel value={neutralPositive} query={query} />
      </li>
      <li>
        <SummaryLabel value={positive} query={query} />
      </li>
      <li>
        <SummaryLabel value={strongPositive} query={query} />
      </li>
    </ul>
  );
};

const SummaryLabel = ({ value: { scoreName, percentage }, query }) => {
  const hasSentiment = percentage > 0;
  const percentageLabel = percentage || 0;

  let body = (
    <div data-testid={`sentiment-label:${kebabCase(scoreName)}`}>
      <strong>{scoreName}</strong>
      <p>{percentageLabel}% of mentions</p>
    </div>
  );

  if (hasSentiment) {
    body = <Link to={linkToSentimentFilteredMentions(scoreName, query)}>{body}</Link>;
  }

  return (
    <div className={`sentiment-label`}>
      <div className={`swatch ${kebabCase(scoreName)}`}></div>
      {body}
    </div>
  );
};

const linkToSentimentFilteredMentions = (sentimentScoreName, query) => {
  const filters = query.cloneParams();

  filters['sentiment[]'] = sentimentHelpers.getScoreForName(sentimentScoreName);

  return query.withFilters(filters).locationWithPathname('/mentions');
};

export default withRouter(SummaryBarContent);
