module.exports = {
  queryFilterParams: ["action_path","affiliations","author_id","department_id","dimensions_search_id","doi_prefix","field_of_research_codes","sustainable_development_goals","funders","grant_id","handle_prefix","identifier_list_id","journal_id","journal_list_id","orcid","open_access","open_access_types","order","published_after","published_before","publisher_id","pubmed_search_id","q","scope","sentiment","timeframe","title","type","view","per_page","filter","mention_sources","mention_types","highlights","sentiment","countries","mentioned_after","mentioned_before","mention_sources_types","mention_sources_countries","mention_sources_mentioned_after","mention_sources_mentioned_before","identifier"],
  mentionsFilterParams: ["mention_sources","mention_types","highlights","sentiment","countries","mentioned_after","mentioned_before"],
  sources: ["msm", "blogs", "policy", "patent", "twitter", "peer_reviews", "weibo", "facebook", "wikipedia", "gplus", "linkedin", "reddit", "pinterest", "f1000", "qna", "videos", "guideline", "bluesky"],
  postTypes: ["msm", "blog", "policy", "patent", "tweet", "peer_review", "weibo", "fbwall", "wikipedia", "gplus", "linkedin", "rdt", "pinterest", "f1000", "qna", "guideline", "bluesky"],
  mentionsTabPostTypes: ["msm", "blog", "policy", "patent", "tweet", "original_tweet", "peer_review", "weibo", "fbwall", "wikipedia", "gplus", "linkedin", "rdt", "pinterest", "f1000", "qna", "video", "guideline", "bluesky"],
  mentionSourcesTypes: ["msm", "blog", "policy", "tw", "sw", "fb", "gplus", "rdt", "guideline", "bsk"],
  otherSources: [
    'syllabus',
    'citations',
    'mendeley'
  ],
  timeframes: ["1d", "3d", "1w", "1m", "3m", "6m", "1y"],
  mentionTimeframes: ["mentions_1d", "mentions_3d", "mentions_1w", "mentions_1m", "mentions_3m", "mentions_6m", "mentions_1y", "mentions_at"],
  outputTypes: ["article","book","chapter","dataset","clinical_trial_study_record","news"],
  openAccessTypes: ["bronze","gold","green","hybrid","closed"],
  openAccessAllTypesFilter: "oa_all",
  openAccessClosedType: "closed"
}
