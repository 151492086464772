export function kebabCase(string) {
  if (!string) {
    return '';
  }

  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
}

export function mergeSentimentSummary(appSummary, apiSummary) {
  return {
    strongNegative: { ...appSummary.strongNegative, ...apiSummary.strong_negative },
    negative: { ...appSummary.negative, ...apiSummary.negative },
    neutralNegative: { ...appSummary.neutralNegative, ...apiSummary.neutral_negative },
    neutral: { ...appSummary.neutral, ...apiSummary.neutral },
    neutralPositive: { ...appSummary.neutralPositive, ...apiSummary.neutral_positive },
    positive: { ...appSummary.positive, ...apiSummary.positive },
    strongPositive: { ...appSummary.strongPositive, ...apiSummary.strong_positive }
  };
}

export function mergeMentionCounts(appMentionCounts, apiMentionCounts) {
  return {
    ...appMentionCounts,
    ...{
      withSentiment: apiMentionCounts.with_sentiment
    }
  };
}
