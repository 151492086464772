import React from 'react';

const Fallback = ({ isLoading, mentionCounts, didFail, searchParamsPresent }) => {
  if (isLoading) {
    return <LoadingSpinner />;
  } else if (didFail) {
    return <FallbackError />;
  } else if (!searchParamsPresent) {
    return <FallbackNoSearchParams />;
  } else if (mentionCounts.withSentiment === 0) {
    return <FallbackNoSentiment />;
  }
  return <></>; // should never happen
};

const LoadingSpinner = () => <div className="Highlights-loading-message" />;

const FallbackNoSentiment = () => {
  return (
    <p className="Sentiment-fallback" data-testid="no-sentiment">
      There are no X/Bluesky mentions with Sentiment Analysis for this search. Some mentions may still exist without
      sentiment analysis.
    </p>
  );
};

const FallbackError = () => {
  return <p className="Sentiment-fallback">There was a problem loading the content. Please try again later.</p>;
};

const FallbackNoSearchParams = () => {
  return (
    <p className="Sentiment-fallback" data-testid="no-search-params">
      Start a search to see the related Sentiment Analysis.
    </p>
  );
};

export default Fallback;
