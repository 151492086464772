import './styles';
import React from 'react';
import AiSparklesIcon from 'icons/ai-sparkles.svg';
import Dropdown from 'components/Explorer/Dropdown';
import sentimentHelpers from 'components/Explorer/util/sentiment_helpers';

class SentimentFilter extends React.Component {
  constructor(props) {
    super(props);

    const { assignRef } = this.props;
    if (assignRef) {
      assignRef(this);
    }

    this.state = {
      selectedOptions: this.props.filterSet.get('sentiment[]')
    };
  }

  componentDidUpdate() {
    if (this.state.selectedOptions.length === 0) {
      this.props.filterSet.clear('sentiment[]');
    } else {
      this.props.filterSet.set('sentiment[]', this.state.selectedOptions);
    }
  }

  clear() {
    this.props.filterSet.clear('sentiment[]');
    this.setState({ selectedOptions: [] });
  }

  get count() {
    return this.state.selectedOptions.length > 0 ? ` (${this.state.selectedOptions.length})` : '';
  }

  setSelectedOptions(selectedOptions) {
    this.setState({ selectedOptions });
  }

  toggleSelectedOption(value) {
    const selectedOptions = this.state.selectedOptions;

    if (selectedOptions.includes(value)) {
      this.setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      this.setSelectedOptions([...selectedOptions, value]);
    }
  }

  render() {
    return (
      <Dropdown
        id="mentions"
        buttonText={
          <>
            Sentiment for Bluesky and X <span className="SentimentFilter-count">{this.count}</span>
          </>
        }
        isDisabled={this.props.isDisabled}
      >
        <div className="SentimentFilter-dropdown">
          <label className="SentimentFilter-option">
            <input
              type="checkbox"
              name="sentiment[]"
              value="all"
              checked={this.state.selectedOptions.length === 0}
              onChange={() => this.setSelectedOptions([])}
              className="SentimentFilter-checkbox"
            />
            All sentiment
          </label>
          {sentimentHelpers.namesWithScores.map(([sentimentName, sentimentScore]) => (
            <label key={sentimentScore} className="SentimentFilter-option">
              <input
                type="checkbox"
                name="sentiment[]"
                value={sentimentScore}
                checked={this.state.selectedOptions.includes(sentimentScore)}
                onChange={() => this.toggleSelectedOption(sentimentScore)}
                className="SentimentFilter-checkbox"
              />
              {sentimentName}
            </label>
          ))}
          <hr />
          <div className="SentimentFilter-disclaimer">
            <AiSparklesIcon />
            This feature uses AI to analyse Bluesky and X (formerly Twitter) posts. AI models can give incorrect
            answers.
          </div>
        </div>
      </Dropdown>
    );
  }
}

export default SentimentFilter;
