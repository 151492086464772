import React from 'react';
import './styles';
import SentimentSwatch from './SentimentSwatch';
import FeedbackWorkflow from './FeedbackWorkflow';

const Sentiment = ({ output, post }) => {
  return (
    <div className="Mentions-Output-Sentiment-Container">
      <SentimentSwatch sentimentValue={output.sentimentValue} />
      <span className="vertical-bar">|</span>
      <FeedbackWorkflow
        postType={post.postType}
        researchOutputId={output.id}
        postId={post.id}
        postUrl={post.url}
        sentimentValue={output.sentimentValue}
      />
    </div>
  );
};

export default Sentiment;
