import React from 'react';
import './styles';

const SentimentSwatch = ({ sentimentValue }) => {
  const valueAsClassName = sentimentValue.replace(' ', '-').toLowerCase();

  return (
    <div className="Mentions-Output-Sentiment-Swatch">
      <div className={`sentiment-icon ${valueAsClassName}`} data-testid="sentiment-icon"></div>
      <p>{sentimentValue}</p>
    </div>
  );
};

export default SentimentSwatch;
