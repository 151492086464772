import * as React from 'react';
const ThumbsUp = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <mask
      id="a"
      width={16}
      height={16}
      x={2}
      y={2}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha'
      }}
    >
      <path fill="#D9D9D9" d="M2 2h16v16H2z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#383844"
        d="M14 16H6.667V7.333l4.666-4.666.834.833a.875.875 0 0 1 .191.317c.05.133.075.26.075.383v.233l-.733 2.9H16c.355 0 .667.134.933.4.267.267.4.578.4.934V10c0 .078-.01.161-.033.25a7.801 7.801 0 0 1-.067.25l-2 4.7c-.1.222-.266.411-.5.567A1.303 1.303 0 0 1 14 16Zm-6-1.333h6L16 10V8.667h-6L10.9 5 8 7.9v6.767ZM6.667 7.333v1.334h-2v6h2V16H3.333V7.333h3.334Z"
      />
    </g>
  </svg>
);
export default ThumbsUp;
