import './styles';
import React, { useLayoutEffect, useRef, useState } from 'react';

// eslint-disable-next-line no-prototype-builtins
const supportsPopover = () => HTMLElement.prototype.hasOwnProperty('popover');
const supportsAnchorPositioning = () => CSS.supports('anchor-name', '--a');

const Dropdown = ({ id, buttonText, isDisabled, children }) => {
  const buttonRef = useRef(null);
  const popoverRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const anchorName = `--${id}`;
  const buttonStyle = { anchorName: anchorName };
  const popoverStyle = { positionAnchor: anchorName };

  const ariaAttributes = supportsPopover()
    ? {}
    : {
        'aria-controls': id,
        'aria-expanded': isOpen.toString()
      };

  useLayoutEffect(() => {
    if (!supportsAnchorPositioning()) {
      const { bottom, left } = buttonRef.current.getBoundingClientRect();

      Object.assign(popoverRef.current.style, {
        top: `${bottom}px`,
        left: `${left}px`
      });
    }
  }, [isOpen]);

  return (
    <>
      <button
        ref={buttonRef}
        popovertarget={id}
        className="Dropdown-button"
        disabled={isDisabled}
        onClick={() => setIsOpen(!isOpen)}
        style={buttonStyle}
        {...ariaAttributes}
      >
        {buttonText}
      </button>
      <div
        ref={popoverRef}
        id={id}
        className={`Dropdown-popover ${isOpen ? 'is-open' : ''}`}
        popover="auto"
        style={popoverStyle}
        data-testid="popover"
      >
        {children}
      </div>
    </>
  );
};

export default Dropdown;
